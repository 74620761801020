import React, { ReactElement } from "react";

export default function HowThisWorkImageIcon(): ReactElement {
    return (
        <svg
            width="548"
            height="439"
            viewBox="0 0 548 439"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.92442 229.568C-4.51206 172.623 -1.39302 39.6078 119.518 5.02449C251.12 -32.6209 312.486 154.023 367.632 141.812C367.632 141.812 481.732 114.144 496.414 235.729C511.095 357.314 342.639 409.822 172.208 390.131C92.9076 380.967 11.435 313.72 1.92442 229.568Z"
                fill="#EFF1F7"
            />
            <path
                d="M510.646 123.188H222.952C212.308 123.188 203.68 131.793 203.68 142.408V304.319C203.68 314.935 212.308 323.54 222.952 323.54H510.646C521.29 323.54 529.918 314.935 529.918 304.319V142.408C529.918 131.793 521.29 123.188 510.646 123.188Z"
                fill="#A2BCD8"
            />
            <path
                d="M505.295 175.691H233.48C228.863 175.691 225.119 179.425 225.119 184.03V190.939C225.119 195.544 228.863 199.277 233.48 199.277H505.295C509.913 199.277 513.656 195.544 513.656 190.939V184.03C513.656 179.425 509.913 175.691 505.295 175.691Z"
                fill="#EFF1F7"
            />
            <path
                d="M529.918 254.969H203.68V282.494H529.918V254.969Z"
                fill="#819FBE"
            />
            <path
                d="M318.267 438.074C422.319 438.074 506.67 431.942 506.67 424.377C506.67 416.812 422.319 410.68 318.267 410.68C214.215 410.68 129.864 416.812 129.864 424.377C129.864 431.942 214.215 438.074 318.267 438.074Z"
                fill="#E9E9E9"
            />
            <path
                d="M465.216 226.543H181.153C170.642 226.543 162.121 235.041 162.121 245.524V405.39C162.121 415.873 170.642 424.371 181.153 424.371H465.216C475.727 424.371 484.248 415.873 484.248 405.39V245.524C484.248 235.041 475.727 226.543 465.216 226.543Z"
                fill="#071A6D"
            />
            <path
                d="M459.506 226.543H175.443C164.932 226.543 156.411 235.041 156.411 245.524V405.39C156.411 415.873 164.932 424.371 175.443 424.371H459.506C470.017 424.371 478.538 415.873 478.538 405.39V245.524C478.538 235.041 470.017 226.543 459.506 226.543Z"
                fill="#ADD6FF"
            />
            <path
                d="M228.218 299.956C238.572 289.629 238.572 272.887 228.218 262.56C217.864 252.234 201.077 252.234 190.723 262.56C180.368 272.887 180.368 289.629 190.722 299.956C201.077 310.282 217.864 310.282 228.218 299.956Z"
                fill="white"
            />
            <path
                d="M430.185 281.258H378.15C368.596 281.258 360.852 288.982 360.852 298.51V317.451C360.852 326.979 368.596 334.703 378.15 334.703H430.185C439.739 334.703 447.484 326.979 447.484 317.451V298.51C447.484 288.982 439.739 281.258 430.185 281.258Z"
                fill="#A2BCD8"
            />
            <path
                d="M268.284 394.062H186.044C184.338 394.062 182.955 395.442 182.955 397.143V399.698C182.955 401.399 184.338 402.778 186.044 402.778H268.284C269.99 402.778 271.373 401.399 271.373 399.698V397.143C271.373 395.442 269.99 394.062 268.284 394.062Z"
                fill="white"
            />
            <path
                d="M444.391 394.062H362.151C360.445 394.062 359.062 395.442 359.062 397.143V399.698C359.062 401.399 360.445 402.778 362.151 402.778H444.391C446.097 402.778 447.48 401.399 447.48 399.698V397.143C447.48 395.442 446.097 394.062 444.391 394.062Z"
                fill="white"
            />
            <path
                d="M444.391 254.254H362.151C360.445 254.254 359.062 255.633 359.062 257.335V259.889C359.062 261.59 360.445 262.97 362.151 262.97H444.391C446.097 262.97 447.48 261.59 447.48 259.889V257.335C447.48 255.633 446.097 254.254 444.391 254.254Z"
                fill="white"
            />
            <path
                d="M229.804 357.07H189.133C185.721 357.07 182.955 359.829 182.955 363.232V368.337C182.955 371.74 185.721 374.498 189.133 374.498H229.804C233.216 374.498 235.982 371.74 235.982 368.337V363.232C235.982 359.829 233.216 357.07 229.804 357.07Z"
                fill="white"
            />
            <path
                d="M300.601 357.07H259.93C256.518 357.07 253.752 359.829 253.752 363.232V368.337C253.752 371.74 256.518 374.498 259.93 374.498H300.601C304.013 374.498 306.779 371.74 306.779 368.337V363.232C306.779 359.829 304.013 357.07 300.601 357.07Z"
                fill="white"
            />
            <path
                d="M371.399 357.07H330.728C327.316 357.07 324.55 359.829 324.55 363.232V368.337C324.55 371.74 327.316 374.498 330.728 374.498H371.399C374.811 374.498 377.577 371.74 377.577 368.337V363.232C377.577 359.829 374.811 357.07 371.399 357.07Z"
                fill="white"
            />
            <path
                d="M442.197 357.07H401.526C398.114 357.07 395.348 359.829 395.348 363.232V368.337C395.348 371.74 398.114 374.498 401.526 374.498H442.197C445.609 374.498 448.375 371.74 448.375 368.337V363.232C448.375 359.829 445.609 357.07 442.197 357.07Z"
                fill="white"
            />
            <path
                d="M116.445 439.002C151.608 439.002 180.113 436.883 180.113 434.27C180.113 431.657 151.608 429.539 116.445 429.539C81.2817 429.539 52.7764 431.657 52.7764 434.27C52.7764 436.883 81.2817 439.002 116.445 439.002Z"
                fill="#E9E9E9"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M122.129 426.171C122.129 426.171 109.892 373.67 118.493 351.825L107.088 280.186L140.963 279.812L129.85 425.977L122.129 426.171Z"
                fill="#E3633D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M129.868 423.991L136.402 429.092C137.678 430.343 144.789 432.931 144.789 432.931C145.452 434.451 144.04 435.171 142.831 435.343L121.627 434.163C121.627 434.163 116.759 432.979 121.432 423.707C125.236 425.544 128.759 426.885 129.868 423.991Z"
                fill="#2E203D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M144.209 290.891L141.318 352.559L135.979 414.578L111.326 416.546C111.326 416.546 112.775 405.716 112.232 402.356C111.689 398.995 108.222 367.048 114.55 351.715L109.136 291.761L144.209 290.891Z"
                fill="#0E2CA9"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M114.377 351.741L108.963 291.783L120.716 291.488L124.682 335.396C125.368 343.657 124.999 351.972 123.581 360.14C120.866 376.447 123.304 398.681 123.753 401.448C124.296 404.809 122.847 415.639 122.847 415.639L111.153 416.572C111.153 416.572 112.599 405.743 112.056 402.382C111.513 399.021 108.046 367.074 114.377 351.741Z"
                fill="#071A6D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M103.139 110.653C103.231 109.552 104.199 108.735 105.299 108.827C111.682 109.365 118.216 110.927 124.784 113.661C125.804 114.085 126.286 115.256 125.862 116.275C125.438 117.295 124.267 117.778 123.247 117.354C117.052 114.775 110.923 113.315 104.964 112.813C103.863 112.721 103.046 111.753 103.139 110.653ZM69.6231 119.077C70.2106 120.013 69.9287 121.247 68.9933 121.835C58.3483 128.521 49.6091 138.364 43.9712 149.731C43.4805 150.721 42.2804 151.125 41.2909 150.634C40.3013 150.144 39.897 148.944 40.3878 147.954C46.3399 135.953 55.5713 125.542 66.8657 118.448C67.8011 117.86 69.0356 118.142 69.6231 119.077ZM35.2862 184.757C36.381 184.61 37.3875 185.379 37.5342 186.474C38.3487 192.547 40.1367 198.595 43.0288 204.441C43.5186 205.431 43.113 206.631 42.123 207.121C41.1329 207.61 39.9333 207.205 39.4435 206.215C36.3537 199.969 34.4407 193.501 33.5697 187.005C33.4229 185.911 34.1914 184.904 35.2862 184.757Z"
                fill="#0E5AA9"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M198.546 29.9023C195.812 29.9023 193.608 32.1112 193.608 34.819C193.608 37.5269 195.812 39.7358 198.546 39.7358C201.281 39.7358 203.484 37.5269 203.484 34.819C203.484 32.1112 201.281 29.9023 198.546 29.9023ZM187.608 34.819C187.608 28.7823 192.513 23.9023 198.546 23.9023C204.58 23.9023 209.484 28.7823 209.484 34.819C209.484 40.8558 204.58 45.7358 198.546 45.7358C192.513 45.7358 187.608 40.8558 187.608 34.819Z"
                fill="#0E5AA9"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M536.939 366.078C534.204 366.078 532.001 368.287 532.001 370.995C532.001 373.703 534.204 375.912 536.939 375.912C539.674 375.912 541.877 373.703 541.877 370.995C541.877 368.287 539.674 366.078 536.939 366.078ZM526.001 370.995C526.001 364.958 530.906 360.078 536.939 360.078C542.972 360.078 547.877 364.958 547.877 370.995C547.877 377.032 542.972 381.912 536.939 381.912C530.906 381.912 526.001 377.032 526.001 370.995Z"
                fill="#0E5AA9"
            />
            <path
                d="M192.401 181.521C215.436 181.521 234.109 162.897 234.109 139.924C234.109 116.951 215.436 98.3281 192.401 98.3281C169.367 98.3281 150.693 116.951 150.693 139.924C150.693 162.897 169.367 181.521 192.401 181.521Z"
                fill="#FF8A00"
            />
            <path
                d="M214.126 104.414C220.232 108.127 225.277 113.344 228.776 119.563C232.276 125.782 234.113 132.795 234.109 139.927C234.108 150.959 229.714 161.538 221.892 169.338C214.07 177.138 203.462 181.52 192.401 181.52C181.34 181.52 170.732 177.138 162.911 169.338C155.089 161.538 150.694 150.959 150.693 139.927C150.677 130.921 153.604 122.154 159.032 114.956"
                stroke="#0E5AA9"
                strokeWidth="4.85"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M204.089 102.515C200.307 101.343 196.369 100.75 192.409 100.756L192.404 100.756C190.634 100.756 188.865 100.873 187.111 101.108C185.783 101.286 184.563 100.354 184.385 99.0267C184.207 97.6993 185.139 96.479 186.467 96.3012C188.435 96.0375 190.418 95.9056 192.404 95.9063C196.852 95.9 201.275 96.5661 205.524 97.882C206.803 98.2783 207.519 99.6366 207.123 100.916C206.726 102.195 205.368 102.911 204.089 102.515Z"
                fill="#0E5AA9"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M102.553 262.172L102.036 350.611L98.8008 425.648L91.7765 425.917C87.4818 399.127 73.0549 379.612 80.259 347.187L77.1699 286.807L88.7548 265.092L102.553 262.172Z"
                fill="#C95836"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M104.673 261.715L104.351 352.552L103.767 415.475C103.797 416.4 103.46 417.3 102.829 417.979C102.198 418.657 101.324 419.061 100.397 419.101L87.318 419.474C86.6235 419.492 85.9479 419.247 85.428 418.787C84.908 418.327 84.5825 417.688 84.5172 416.998C83.9294 411.3 82.6039 404.074 81.8962 400.784C78.3466 384.696 74.827 376.055 77.2009 347.955L73.5801 285.775L88.7858 265.068L104.673 261.715Z"
                fill="#0E2CA9"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M85.3775 244.164L126.835 245.908C138.296 267.149 136.252 293.658 136.252 293.658L105.724 295.063C91.6044 294.767 74.9047 305.814 73.1486 282.366C71.1379 255.524 85.3775 244.164 85.3775 244.164Z"
                fill="#0E2CA9"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M98.8497 424.905C98.8497 424.905 102.298 430.988 103.523 434.786C103.624 435.098 103.65 435.43 103.599 435.755C103.547 436.079 103.42 436.387 103.227 436.653C103.032 436.915 102.776 437.124 102.481 437.264C102.186 437.403 101.861 437.468 101.534 437.452L97.4493 437.265C96.8119 437.235 96.2019 436.997 95.712 436.59L94.6748 435.731C92.9449 434.3 92.1586 432.717 92.1361 430.503C92.0966 428.879 91.9716 427.259 91.7617 425.648C94.4052 423.001 98.8497 424.905 98.8497 424.905Z"
                fill="#2E203D"
            />
            <path
                d="M173.511 220.549L170.845 223.806C169.921 224.937 168.62 225.699 167.179 225.953L161.346 226.987C160.876 227.07 160.392 227.038 159.937 226.894C159.482 226.75 159.068 226.498 158.732 226.159C158.396 225.821 158.147 225.406 158.007 224.95C157.867 224.495 157.839 224.012 157.927 223.544L164.615 218.888C165.985 217.394 171.062 216.225 173.309 215.938C173.905 216.547 174.255 217.354 174.292 218.205C174.329 219.056 174.051 219.89 173.511 220.549V220.549Z"
                fill="#C95836"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M119.852 191.352C119.852 191.352 105.545 190.355 105.537 178.947C105.53 167.538 113.715 166.25 113.715 166.25L122.585 183.682L119.852 191.352Z"
                fill="#2E203D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M149.11 239.457L136.551 201.539C133.41 192.913 124.764 189.418 118.38 194.194C115.864 196.061 115.149 200.128 116.774 203.291L136.993 242.699L149.11 239.457Z"
                fill="#C95836"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M126.686 192.255L114.621 188.065L104.268 186.855L99.191 187.057L79.8928 193.779C76.7569 195.325 74.3044 197.972 73.0064 201.211C71.7085 204.451 71.6566 208.055 72.861 211.33L77.5863 233.362C72.569 249.98 69.8168 254.118 70.6892 271.217C95.8997 275.586 138.836 271.217 140.839 268.932C139.368 234.696 135.376 209.508 126.686 192.255Z"
                fill="#A5BFDB"
            />
            <path
                d="M114.355 188.087C114.355 188.087 120.425 203.069 110.113 203.726C99.8011 204.383 94.1621 188.815 94.1621 188.815L101.692 185.477L114.355 188.087Z"
                fill="#C95836"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M68.6478 255.781L86.115 214.252C90.2337 205.682 86.8264 197.508 79.0008 197.074C75.9155 196.903 72.4895 199.535 71.31 202.978L56.9355 247.211L68.6478 255.781Z"
                fill="#C95836"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M56.9062 247.215C59.1528 254.24 64.9191 258.915 73.1079 257.593L110.622 242.969L104.594 236.621L74.8116 242.805L56.9062 247.215Z"
                fill="#C95836"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M90.7402 207.1L81.3045 232.691L60.1865 225.084C64.2379 220.692 68.4241 199.691 79.8967 193.768C92.4888 187.259 90.7402 207.1 90.7402 207.1Z"
                fill="#A5BFDB"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M126.685 192.258C126.685 192.258 134.829 192.818 139.127 206.851C143.426 220.885 147.215 223.738 147.215 223.738L131.781 229.974L119.945 200.884L126.685 192.258Z"
                fill="#A5BFDB"
            />
            <path
                d="M135.671 227.604C135.671 227.604 132.639 218.824 130.111 213.865C127.584 208.906 128.482 205 128.482 205"
                stroke="#CEE2E8"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M79.5146 230.972L87.5237 210.303L87.1381 206.105"
                stroke="#CEE2E8"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M105.145 270.781L157.823 263.197L166.087 208.176L120.009 213.867C116.789 214.266 112.521 217.358 111.746 221.53L105.145 270.781Z"
                fill="white"
            />
            <path
                d="M101.599 240.676L105.261 234.357C105.993 233.095 107.156 232.136 108.537 231.657L114.127 229.712C114.578 229.555 115.061 229.509 115.533 229.579C116.006 229.648 116.455 229.831 116.841 230.112C117.227 230.392 117.539 230.762 117.751 231.189C117.962 231.616 118.066 232.088 118.055 232.565V232.565L113.004 240.242C111.78 242.015 109.971 243.302 107.893 243.88L104.276 244.948L101.599 240.676Z"
                fill="#C95836"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M106.574 164.598C106.574 164.598 77.0766 186.869 88.0175 195.219C94.8696 200.447 110.884 175.457 110.884 175.457L106.574 164.598Z"
                fill="#2E203D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M112.797 179.024C112.797 179.024 111.88 185.876 111.124 191.556C111.025 192.298 110.73 193 110.269 193.59C109.809 194.181 109.199 194.639 108.503 194.917C107.812 195.194 107.059 195.281 106.324 195.169C105.588 195.057 104.896 194.749 104.32 194.278L103.197 193.367C102.404 192.718 101.811 191.859 101.485 190.889C101.159 189.918 101.114 188.876 101.355 187.882L104.893 173.18L112.756 179.028L112.797 179.024Z"
                fill="#C95836"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M112.322 183.879L111.461 187.949C108.544 186.967 107.009 184.245 105.762 181.156L112.322 183.879Z"
                fill="#873B24"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M106.185 168.225C106.484 167.303 107.136 166.536 107.999 166.092C108.863 165.648 109.867 165.562 110.794 165.854L117.455 167.997C119.369 168.613 120.102 170.611 119.773 172.601C119.398 174.958 117.526 179.506 115.755 182.643C114.763 184.413 112.707 185.675 110.794 185.059C108.753 184.402 103.275 180.473 103.89 176.608C104.358 173.669 105.256 171.059 106.185 168.225Z"
                fill="#C95836"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M115.782 168.593C115.782 168.593 109.791 173.272 105.762 172.954C101.733 172.637 102.115 174.534 102.115 174.534C102.115 174.534 103.377 164.287 108.945 163.6C112.798 163.13 114.587 162.652 115.782 168.593Z"
                fill="#2E203D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M101.549 175.191C101.598 176.852 102.748 178.152 104.118 178.1C105.488 178.047 106.559 176.658 106.511 174.996C106.462 173.335 105.312 172.035 103.942 172.087C102.572 172.14 101.501 173.533 101.549 175.191Z"
                fill="#C95836"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M122.533 171.29C123.776 174.894 123.151 182.807 124.341 187.949C124.626 189.163 127.258 191.355 126.359 193.061C125.988 193.617 125.662 194.202 125.382 194.809C125.112 195.371 124.676 195.837 124.131 196.143C123.587 196.45 122.961 196.581 122.339 196.52C121.717 196.46 121.129 196.209 120.654 195.804C120.18 195.398 119.842 194.856 119.687 194.252C119.371 193.035 119.137 191.797 118.987 190.548C118.099 182.381 119.103 174.05 115.4 170.891C114.586 170.375 113.918 169.66 113.459 168.814C113.001 167.969 112.766 167.02 112.779 166.059C112.831 162.731 119.533 165.345 122.533 171.29Z"
                fill="#2E203D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M166.117 206.111C166.117 206.111 174.474 205.737 174.83 217.157L122.566 225.764C122.566 225.764 131.849 220.163 120.941 213.863L166.117 206.111Z"
                fill="white"
            />
            <path
                d="M171.901 217.958L122.585 226.962C122.585 226.962 123.072 216.233 115.377 217.559"
                stroke="#9197EB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M171.418 308.085C180.655 300.553 178.708 284.593 170.643 281.116C159.036 276.108 157.823 263.191 157.823 263.191L105.144 270.776C103.391 286.605 113.19 288.723 119.979 293.294C123.817 295.874 122.888 301.565 121.357 306.024C120.396 308.737 119.102 311.321 117.504 313.716C115.542 316.704 112.101 323.575 115.905 330.681C120.978 340.151 139.236 350.1 139.236 350.1L196.453 339.334C196.453 339.334 152.251 337.967 171.418 308.085Z"
                fill="white"
            />
            <path
                d="M175.49 117.749L169.659 123.258L206.544 162.09L212.375 156.582L175.49 117.749Z"
                fill="white"
            />
            <path
                d="M207.721 118.619L168.787 155.402L174.311 161.217L213.245 124.434L207.721 118.619Z"
                fill="white"
            />
        </svg>
    );
}
